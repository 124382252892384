import '@babel/polyfill';
jQuery(function ($) {
    console.log('test.jsが読みこまれた');
    $('body').addClass('jquery-add-class');
});
MicroModal.init({
    disableScroll: false
});
const home = document.querySelector('#top-slider');
if (home) {
    for (let i = 0; i < 15; i++) {
        var swiper = new Swiper(`.top-slider${i}`, {
            spaceBetween: 20,
            centeredSlides: true,
            loop: true,
            loopAdditionalSlides: 1,
            speed: 600,
            autoplay: {
                delay: 2000 + Math.floor(Math.random() * 1000),
                disableOnInteraction: false
            }
        });
    }
}
window.onload = function () {
    var windowWidth = $(window).width();
    var windowSm = 768;
    var sliderWrap = document.querySelectorAll('.slider-wrap');
    var sliderThumb = document.querySelectorAll('.slider-thumbnail');
    var sliderMain = document.querySelectorAll('.slider');
    for (let i = 0; i < sliderWrap.length; i++) {
        var num = ('00' + (i + 1)).slice(-2);
        sliderWrap[i].className += num;
        sliderThumb[i].className += num;
        sliderMain[i].className += num;
        var swiperThumb = new Swiper('.slider-thumbnail' + num, {
            speed: 400,
            slidesPerView: 5,
            spaceBetween: windowWidth <= windowSm ? 20 : 30,
            freeMode: true,
            autoHeight: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true
        });
        var swiperMain = new Swiper('.slider' + num, {
            speed: 400,
            slidesPerView: 1,
            autoHeight: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            },
            thumbs: {
                swiper: swiperThumb
            }
        });
    }
};
const contact = document.querySelector('#contact');
if (home) {
    sessionStorage.removeItem('user');
}
else if (contact) {
    console.log('contact');
}
else {
    if (!sessionStorage.getItem('user')) {
        const psw = prompt('パスワードを入力して下さい:', '');
        if (psw == 'FAbyRls') {
            sessionStorage.setItem('user', 'login');
            window.alert('ログイン成功');
        }
        else {
            window.alert('ログイン失敗');
            location.replace('http://fabricark.com/');
        }
    }
}
const memberBtn = document.getElementById('member');
const inquiryBtn = document.getElementById('inquiry');
const othersBtn = document.getElementById('others');
if (memberBtn) {
    const memberBtnParent = memberBtn.parentNode;
    const othersBtnParent = othersBtn.parentNode;
    const inquiryBtnParent = inquiryBtn.parentNode;
    memberBtn.nextElementSibling.innerHTML = 'to be a member';
    memberBtn.addEventListener('click', () => {
        if (memberBtn.checked) {
            inquiryBtnParent.classList.remove('active');
            othersBtnParent.classList.remove('active');
            memberBtnParent.classList.add('active');
        }
    });
}
if (inquiryBtn) {
    const memberBtnParent = memberBtn.parentNode;
    const othersBtnParent = othersBtn.parentNode;
    const inquiryBtnParent = inquiryBtn.parentNode;
    inquiryBtn.nextElementSibling.innerHTML = 'to make a product inquiry';
    inquiryBtn.addEventListener('click', () => {
        if (inquiryBtn.checked) {
            memberBtnParent.classList.remove('active');
            othersBtnParent.classList.remove('active');
            inquiryBtnParent.classList.add('active');
        }
    });
}
if (othersBtn) {
    const memberBtnParent = memberBtn.parentNode;
    const othersBtnParent = othersBtn.parentNode;
    const inquiryBtnParent = inquiryBtn.parentNode;
    othersBtn.addEventListener('click', () => {
        if (othersBtn.checked) {
            memberBtnParent.classList.remove('active');
            inquiryBtnParent.classList.remove('active');
            othersBtnParent.classList.add('active');
        }
    });
}
